import { authRequest } from './api/auth';

class UsageAnalysisService {
    chart(mode,bot,model,knowledge,start,end) {
        // return authRequest.get(`/chatgpt/upgpt/aiusagerecord/?mode=${mode}&bot=${bot}&model=${model}&start=${start}&end=${end}`);
        return authRequest.get(`/upgpt/v2/usage/aiusagerecord/?mode=${mode}&bot=${bot}&model=${model}&knowledge=${knowledge}&start=${start}&end=${end}`);
    }
    list(mode,bot,model,knowledge,start,end,page,page_item) {
        // return authRequest.get(`/chatgpt/upgpt/aiusagerecord/?mode=${mode}&bot=${bot}&model=${model}&start=${start}&end=${end}&page=${page}&page_item=${page_item}`);
        return authRequest.get(`/upgpt/v2/usage/aiusagerecord/?mode=${mode}&bot=${bot}&model=${model}&knowledge=${knowledge}&start=${start}&end=${end}&page=${page}&page_item=${page_item}`);
    }
    chatlist(mode,bot,start,end,page,page_item,search) {
        // return authRequest.get(`/chatgpt/upgpt/chathistory/?mode=${mode}&bot=${bot}&start=${start}&end=${end}&page=${page}&page_item=${page_item}&search=${search}`);
        return authRequest.get(`/upgpt/v2/usage/chathistory/?mode=${mode}&bot=${bot}&start=${start}&end=${end}&page=${page}&page_item=${page_item}&search=${search}`);
    }
    chatsingle(id,conv_id,search) {
        // return authRequest.get(`/chatgpt/upgpt/chathistory_single/?id=${id}&search=${search}`);
        return authRequest.get(`/upgpt/v2/usage/chathistory_single/?id=${id}&conversation_id=${conv_id}&search=${search}`);
    }
    knowledge_select_list(){
        return authRequest.get(`/upgpt/v2/usage/aiusageknowledgelist/`);
    }
}
export default new UsageAnalysisService();