import { authRequest } from './api/auth';
class UpGPTPortalDataServices {
    getThreadList() {
        return authRequest.get(`/upgpt_portal/thread/`);
    }
    createThread(data){
        return authRequest.post(`/upgpt_portal/thread/`, data);
    }
    getQuestion(data){
        return authRequest.get(`/upgpt_portal/thread/${data.thread_id}/`);
    }


}
export default new UpGPTPortalDataServices();
